import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import UChicagoConfirmation from './images/UChicagoConfirmation.jpg';
import AreYouReady from './images/AreYouReady.svg';
import CrownHeading from './images/crownheading.png';
import CrownBuilding from './images/crownbuilding.png';
import CrownBuilding1 from './images/CrownBuilding.svg';
import CrownFrontText from './images/crownfronttext.jpg';
import CrownSchoolLogo from './images/CROWNIMAGE.jpeg';
// import CrownFrntText from './images/crownLogo.png';

function App() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [currentPage, setCurrentPage] = useState(1)

  const { register, watch, handleSubmit, reset, formState: { errors } } = useForm({ defaultValues: {},});
  //console.log(errors);

  const watchFirstName = watch("FirstName");
  const watchAllFields = watch();

  const onSubmit = (data) => {
    //console.log('Data being posted', data)
    setNextPage()
  };

  const setNextPage = () => {
    currentPage === 1 && setCurrentPage(2)
    currentPage === 2 && setCurrentPage(3)
  }

  const setPrevPage = () => {
    currentPage === 2 && setCurrentPage(1)
  }
  
  const sendMail = () => {
    const mailto=
      "mailto:admissions@crownschool.uchicago.edu";
    window.location.href = mailto;
  }

  const programDecision = () => {
    if (watchAllFields.HaveBachelorsInSW === "Yes"){
      return (
        <p id="response-content">
          It looks like you're ready for a: <br/>
          <span style={{ fontWeight: '700', fontSize: '20px', marginTop: '5px', marginBottom: '5px'}}>{watchAllFields.ProgramOfInterest}</span><br/>
          With a Bachelor's in Social Work and your aspirations for a secondary degree, we think
          you're ready to take the next step.
        </p>
      )
    } else {
      return (
        <p id="response-content">
        You've got some great experience! <br/>
        It looks like we need to know a bit more info about you in order to give a proper
        recommendation on your next steps.
      </p>
      )
    }
  }

  return (
    <>
      <Modal show={true} onHide={handleClose}>
        {
          currentPage < 3 ? 
          <section id="left-section">
            <img id="crown-heading-img" src={AreYouReady} alt="Are you ready for a graduate program? Find out in 30 seconds!"/>
            <img id="crown-building-img" alt="Classroom building at University of Chicago" src={CrownBuilding1}/>
            <img id="crown-front-text" 
              alt="The University of Chicago: Crown Family School of Social Work, Policy, and Practice. Advancing a More Just and Humane Society"
              src={CrownSchoolLogo}/>
          </section>
          :
          null
        }
        <section id="right-section">
          <form id="app-form" onSubmit={handleSubmit(onSubmit)}>
          {
            currentPage === 1 ? 
            <div id="select-field-container">
              <p className="select-field-text">What is your program of interest?*</p>
              <select className="hh-dropdown" id="select-select" name="Program of Interest"  {...register("ProgramOfInterest", { required: true })}>
                <option value="">Select</option>
                <option value="Masters in Social Work">Masters in Social Work</option>
                <option value=" Masters Social Sector Leadership and Non-Profit Management (SSL)"> Masters Social Sector Leadership and Non-Profit Management (SSL)</option>
                <option value=" Dual Masters (SW & SSL)"> Dual Masters (SW & SSL)</option>
                <option value=" Doctorate in Social Work"> Doctorate in Social Work</option>
              </select>
              <p className="select-field-text">Beginning Semester?*</p>
              <select className="hh-dropdown" name="Beginning Semester" {...register("Beginning Semester? *", { required: true })}>
                <option value="">Select</option>
                {watchAllFields.ProgramOfInterest === "Masters in Social Work" && <option value="Summer 2022">Summer 2022</option>}
                <option value=" Fall 2022"> Fall 2022</option>
                {watchAllFields.ProgramOfInterest === "Masters in Social Work" && <option value=" Summer 2023"> Summer 2023</option>}
                <option value=" Fall 2023"> Fall 2023</option>
              </select>
              <p className="select-field-text">Do you have a Bachelor's in Social Work?*</p>
              <select className="hh-dropdown" name="Have Bachelors in Social Work?" {...register("HaveBachelorsInSW", { required: true })}>
                <option value="">Select</option>
                <option value="Yes">Yes</option>
                <option value=" No"> No</option>
                <option value=" In the process"> In the process</option>
              </select>
              <p className="select-field-text">Do you have a Masters in Social Work?*</p>
              <select className="hh-dropdown" name="Have Masters in Social Work?" {...register("HaveMastersInSW", { required: true })}>
              <option value="">Select</option>
                <option value="Yes">Yes</option>
                <option value=" No"> No</option>
                <option value=" In the process"> In the process</option>
              </select>
            <div>
              <Button id="continue-button" onClick={() => setNextPage()}>CONTINUE</Button>
            </div>
            </div>
            :
            null
          }
            {
              currentPage === 2 ? 
              <div id="input-field-container">
                <input className="input-field" type="text" placeholder="First name" {...register("FirstName", {required: true, maxLength: 80})} />
                <input className="input-field" type="text" placeholder="Last name" {...register("LastName", {required: true, maxLength: 100})} />
                <input className="input-field" type="text" placeholder="Email Address" {...register("EmailAddress", {required: true, pattern: /^\S+@\S+$/i})} />
                <input className="input-field" type="tel" placeholder="Phone Number" {...register("PhoneNumber", {required: false, maxLength: 12})} />
                <p id="small-text">
                  This assessment provides personalized information based on your answers, but results should not be considered definitive or final. 
                  One of our counselors will reach out to offer additional insights and assistance.
                </p>
                <Button id="back-button" onClick={() => setPrevPage()}>BACK</Button>
                <Button className="hh-pd-submit" id="continue-button" type="submit">SEE RESULTS</Button>
              </div>
              : 
              null
            }
            </form>
          </section>
          <section id="confirmation-section">
            {
              currentPage === 3 ? 
              <div id="confirmation-wrapper">
                <header id="confirmation-header">
                  <img style={{ marginTop: '10px' }} src={UChicagoConfirmation} alt="The University of Chicago logo"/>
                </header>
                  <h3 style={{ color: '#820104', margin: '10px'}}>Thank you, {watchFirstName}</h3>
                  <section id="confirmation-response">
                    <p id="response-content">
                      {programDecision()}
                    </p>
                  </section>
                <div id="button-container'">
                  <p id="next-steps-text">
                    Ready to take the next step? Get in touch with a counselor today!
                  </p>
                  <Button id="contact-button" onClick={() => sendMail()}>ADMISSIONS</Button>
                </div>
              </div>
              :
              null
            }
          </section>
      </Modal>
    </>
  );
}

export default App;
